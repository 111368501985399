import * as React from "react";

export default function Home() {
  return (
    <section className="container py-5 mt-5" id="home">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 pe-lg-5 mb-4 mb-lg-0">
          <h1 className="section-title fs-3 fs-md-4 fs-lg-5">
            Unlocking Capital for Business Growth
          </h1>
          <div className="section-description fs-6 fs-md-5">
            A virtual deal-room where global financing meets seamless
            deal-making. We pave the way for innovation, expansion, and
            unparalleled success by connecting businesses to finance.
          </div>
          <a href="opportunities" className="btn btn-danger my-3 my-lg-5 fs-6">
            See Opportunities
          </a>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row g-2">
            <div className="col-6 d-flex justify-content-center align-items-center">
              <img
                alt="Collage Image 1"
                src="/images/1.png"
                className="img-fluid square-img"
              />
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
              <img
                alt="Collage Image 2"
                src="/images/2.png"
                className="img-fluid square-img"
              />
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
              <img
                alt="Collage Image 3"
                src="/images/3.png"
                className="img-fluid square-img"
              />
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
              <img
                alt="Collage Image 4"
                src="/images/4.png"
                className="img-fluid square-img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
