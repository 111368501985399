import * as React from "react";
import ButtonWithIcon from "./ButtonWithIcon";

interface Category {
  name: string;
  href: string;
  icon: React.ReactElement;
}

interface OpportunitiesHeroSectionProps {
  categories: Category[];
}

const OpportunitiesHeroSection: React.FC<OpportunitiesHeroSectionProps> = ({
  categories,
}) => {
  return (
    <section
      className="container-fluid pt-5 mt-5 bg-black text-white"
      id="section-three"
    >
      <div className="row border-bottom pb-5 align-items-center">
        <div className="col-12">
          <div className="container text-center justify-content-center">
            <div className="row">
              <div className="col-12 col-lg-8 offset-lg-2">
                <h6 className="fw-bolder text-smaller">OPPORTUNITIES</h6>
                <span className="fs-2">
                  Discover Financing Opportunities to Propel Your Business to
                  Global Success
                </span>
              </div>
              <div className="col-12 d-flex justify-content-center py-3 mb-3">
                <div
                  className="input-group w-100"
                  style={{ maxWidth: "600px" }}
                >
                  <input
                    type="text"
                    className="form-control py-3 ps-5 rounded-start-5 fs-6"
                    placeholder="Search Opportunity..."
                    aria-label="Search Opportunity..."
                    aria-describedby="basic-addon2"
                  />
                  <button
                    className="btn btn-danger rounded-start-0 rounded-end-5 py-3 px-5 fs-6"
                    type="button"
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="d-flex flex-wrap flex-lg-nowrap justify-content-center py-3 text-hover-danger ">
                {categories.map((category, index) => (
                  <div
                    key={index}
                    className={`${
                      index >= 4 && category.name !== "More filters"
                        ? "d-none d-md-block"
                        : ""
                    }`}
                  >
                    <ButtonWithIcon
                      name={category.name}
                      href={category.href}
                      icon={category.icon}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OpportunitiesHeroSection;
