import * as React from 'react';
import {Link, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

export default function PageHeader() {

    const location = useLocation();
    const [activeLink, setActiveLink] = useState<string>('');

    useEffect(() => {
        setActiveLink(location.pathname + location.hash);
    }, [location.pathname, location.hash]);

    const handleLinkClick = (link: string) => {
        setActiveLink(link);
    };

    return (
        <nav className="navbar navbar-expand-lg bg-white fixed-top border-bottom">
            <div className="container">
                <a className="navbar-brand" href="#">
                    <img src={'logo.png'} className="navbar-logo"/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a
                                className={`nav-link fs-6 fw-medium text-hover-danger ${activeLink === '/#home' ? 'text-danger' : ''}`}
                                href="/#home"
                                onClick={() => handleLinkClick('/#home')}
                            >
                                Home
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link fs-6 fw-medium text-hover-danger ${activeLink === '/#about' ? 'text-danger' : ''}`}
                                href="/#about"
                                onClick={() => handleLinkClick('/#about')}
                            >
                                About
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link fs-6 fw-medium text-hover-danger ${activeLink === '/#features' ? 'text-danger' : ''}`}
                                href="/#features"
                                onClick={() => handleLinkClick('/#features')}
                            >
                                Features
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link fs-6 fw-medium text-hover-danger ${activeLink === '/#value' ? 'text-danger' : ''}`}
                                href="/#value"
                                onClick={() => handleLinkClick('/#value')}
                            >
                                Value
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link fs-6 fw-medium text-hover-danger ${activeLink === '/#faqs' ? 'text-danger' : ''}`}
                                href="/#faqs"
                                onClick={() => handleLinkClick('/#faqs')}
                            >
                                FAQs
                            </a>
                        </li>
                        <li className="nav-item">
                            <Link
                                className={`nav-link fs-6 fw-medium text-hover-danger ${activeLink === '/opportunities' ? 'text-danger' : ''}`}
                                to="/opportunities"
                                onClick={() => handleLinkClick('/opportunities')}
                            >
                                Opportunities
                            </Link>
                        </li>
                    </ul>
                    <div className="d-flex justify-content-center align-items-center gap-3 ">
                        <a target="_blank" href="https://app.black.asigmagroup.com"
                           className="btn btn-outline-danger fs-6">Login</a>
                        <a target="_blank" href="https://app.black.asigmagroup.com" className="btn btn-danger fs-6">Create
                            Account</a>
                    </div>
                </div>
            </div>
        </nav>
    );
}