import PageHeader from "./PageHeader";
import Home from "./Home";
import About from "./About";
import Features from "./Features";
import Value from "./Value";
import Story from "./Story";
import FAQs from "./FAQs";
import Footer from "./Footer";
import React from "react";

export default function LandingPage() {
    return(
        <div className="App">
            <PageHeader/>
            <Home/>
            <About/>
            <Features/>
            <Value />
            <Story />
            <FAQs />
            <Footer />
        </div>
    )
}