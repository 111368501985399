import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function FAQs() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent) => {
    setExpanded(expanded === panel ? false : panel);
  };

  return (
    <section className="container-fluid py-5 my-5 bg-white" id="faqs">
      <div className="row">
        <div className="col-12">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4 order-lg-1 order-2">
                <img
                  className="mx-auto d-block"
                  alt=""
                  style={{ width: "80%", height: "auto" }}
                  src="/images/5.png"
                />
              </div>
              <div className="col-12 col-lg-8 order-lg-2 order-1">
                <h3>
                  Frequently Asked <strong>Questions</strong>
                </h3>
                <hr />
                <>
                  <Accordion
                    elevation={0}
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      className="accordion-summary"
                      expandIcon={
                        expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                      }
                      aria-controls={"panel1-content"}
                      id="panel1-header"
                    >
                      <div className="summary-text">
                        Which financiers does ASIGMA have in their pipeline?
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      The financiers in ASIGMA's pipeline are not limited to
                      commercial banks and traditional lending institutions.
                      ASIGMA has a wide range of financiers that include
                      development financial institutions, development banks,
                      private equity firms, venture capital firms, among others
                      who offer capital at a more affordable cost especially for
                      strategic sectors.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      style={{ paddingTop: "15px", paddingBottom: "15px" }}
                      expandIcon={
                        expanded === "panel2" ? <RemoveIcon /> : <AddIcon />
                      }
                      aria-controls={"panel2-content"}
                      id="panel2-header"
                    >
                      <div style={{ width: "80%" }}>
                        <strong> What sectors are considered?</strong>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      ASIGMA's Investment Readiness and Fundraising Program is
                      sector agnostic. Most financiers are willing to work with
                      companies in multiple sectors given that the underlying
                      company fundamentals are sound.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                  >
                    <AccordionSummary
                      style={{ paddingTop: "15px", paddingBottom: "15px" }}
                      expandIcon={
                        expanded === "panel3" ? <RemoveIcon /> : <AddIcon />
                      }
                      aria-controls={"panel3-content"}
                      id="panel3-header"
                    >
                      <div style={{ width: "80%" }}>
                        <strong>
                          How much do I pay to join the Investment Readiness and
                          Fundraising Program?
                        </strong>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      There is no initial or monthly fee for the Investment
                      Readiness and Fundraising Program. ASIGMA benefits through
                      a success fee earned when a deal is closed and your
                      company has successfully received financing.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                  >
                    <AccordionSummary
                      style={{ paddingTop: "15px", paddingBottom: "5px" }}
                      expandIcon={
                        expanded === "panel4" ? <RemoveIcon /> : <AddIcon />
                      }
                      aria-controls={"panel4-content"}
                      id="panel4-header"
                    >
                      <div style={{ width: "80%" }}>
                        <strong>
                          Besides access to finance, what are the other benefits
                          of the Investment Readiness and Fundraising Program?
                        </strong>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      On top of identifying and facilitating the introduction to
                      potential financiers, ASIGMA will walk the entire
                      fundraising journey with you. We are committed to
                      providing comprehensive transaction advisory support,
                      which comprises of preparing essential fundraising
                      documents, assisting with deal structuring, and reviewing
                      term sheets. Our goal is to alleviate your workload and
                      ensure no money is left on the table.
                    </AccordionDetails>
                  </Accordion>
                </>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
