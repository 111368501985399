import * as React from "react";

export default function About() {
  return (
    <section
      className="container-fluid py-5 mt-5 bg-black text-white"
      id="about"
    >
      <div className="row">
        <div className="col-12">
          <div className="container">
            <div className="row align-items-center">
              {/* Image section with floating behavior only on large screens */}
              <div className="col-12 col-lg-6 order-lg-1 order-2">
                <img
                  className="d-none d-lg-block floating-image"
                  alt=""
                  src="/images/6.png"
                  style={{ width: "100%", height: "auto" }}
                />
                <img
                  className="d-block d-lg-none"
                  alt=""
                  src="/images/6.png"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>

              <div className="col-12 col-lg-6 order-lg-2 order-1">
                <label className="text-uppercase fs-6 my-3">about</label>
                <h3>
                  Revolutionizing Investment <br />
                  Readiness & Fundraising
                </h3>
                <div className="section-description py-3">
                  <p>
                    Project Black is a virtual deal room at the heart of
                    ASIGMA's Investment Readiness and Fundraising Program
                    (IRFP). Crafted as a strategic initiative, it centralizes
                    investor matchmaking, due diligence, and transaction
                    advisory support activities, offering a convenient and
                    secure space accessible to investors, businesses, and
                    service providers worldwide.
                  </p>
                  <p>
                    Project Black aims to redefine the collaboration between
                    businesses seeking funding and investors aiming to deploy
                    capital, transforming the deal-making journey to a faster
                    and less complex journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
