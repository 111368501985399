import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";

interface ButtonWithIconProps {
  name: string;
  href: string;
  icon: React.ReactElement;
}

const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  name,
  href,
  icon,
}) => {
  const [active, setActive] = useState<string | null>(null);

  const handleClick = () => {
    setActive((prevActive) => (prevActive === name ? null : name));
  };

  return (
    <a
      href={href}
      className={`btn rounded-5 bg-white text-smaller mx-2 my-1 px-4 h-35 d-flex align-items-center ${
        active === name ? "text-danger" : ""
      }`}
      onClick={handleClick}
    >
      <IconButton size="small" edge="start" color="inherit" aria-label={name}>
        {icon}
      </IconButton>
      <span className="ms-0">{name}</span>
    </a>
  );
};

export default ButtonWithIcon;
