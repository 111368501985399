import * as React from 'react';


interface IProps {
    label: string
    value: string
}

const NumbersCard = ({label, value}: IProps) => {
    return (
        <section className="story-numbers">
            <label>{label}</label>
            <h1>{value}</h1>
        </section>
    )
}


export default function Story() {

    return (
        <section className="container-fluid py-5 mt-5 bg-black text-white" id="story">
            <div className="row">
                <div className="col-12">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-2">
                                <h3 className="fw-bolder">Our story in numbers</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-lg-3">
                                <NumbersCard label={"businesses helped"} value={"72+"} />
                            </div>
                            <div className="col-6 col-lg-3">
                                <NumbersCard label={"financiers"} value={"45+"} />
                            </div>
                            <div className="col-6 col-lg-3">
                                <NumbersCard label={"service providers"} value={"210+"} />
                            </div>
                            <div className="col-6 col-lg-3">
                                <NumbersCard label={"amount raised"} value={"$456M"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}