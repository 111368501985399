import * as React from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';


interface IProps {
    label: string
    value: string
}

const NumbersCard = ({label, value}: IProps) => {
    return (
        <section className="story-numbers">
            <label>{label}</label>
            <h1>{value}</h1>
        </section>
    )
}


export default function Footer() {

    return (
        <section className="container-fluid pt-5 bg-black text-white" id="section-two">
            {/*<div className="row border-bottom pb-5">*/}
            {/*    <div className="col-12">*/}
            {/*        <div className="container text-center">*/}
            {/*            <div className="row">*/}
            {/*                <div className="col-12">*/}
            {/*                    <h3 className="fw-bolder">Sign up to join the program</h3>*/}
            {/*                </div>*/}
            {/*                <div className="col-12 py-3">*/}
            {/*                    <a href="" className="btn rounded-5 btn-primary border-0 text-smaller mx-3 px-4"><BusinessIcon fontSize="small" style={{ marginRight: '8px' }} />For Businesses</a>*/}
            {/*                    <a href="" className="btn rounded-5 bg-white text-smaller mx-3 px-4"><AccountBalanceIcon fontSize="small" style={{ marginRight: '8px' }} />For Financiers</a>*/}
            {/*                    <a href="" className="btn rounded-5 bg-white text-smaller mx-3 px-4"><MiscellaneousServicesIcon fontSize="small" style={{ marginRight: '8px' }} />Service Providers</a>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="row">
                <div className="col-12">
                    <div className="container text-center">
                        <div className="py-3 text-smaller">
                            &copy; 2024 All Rights Reserved. Project Black is a product of <a href="https://asigmagroup.com" target="_blank">ASIGMA</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}