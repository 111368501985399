import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

interface OpportunityCardProps {
  logo: string;
  title: string;
  amount: string;
  tags: string[];
  description: string;
  deadline: string;
  location: string;
}

const stripHTML = (html: any) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.innerText || tempDiv.textContent || "";
};

const OpportunityCard: React.FC<OpportunityCardProps> = ({
  logo,
  title,
  amount,
  tags,
  description,
  deadline,
  location,
}) => {
  const maxLength = 200;
  const plaintext = stripHTML(description);
  const displayText =
    plaintext.length > maxLength
      ? `${plaintext.slice(0, maxLength)}...`
      : plaintext;

  return (
    <div className="card mb-3 border border-gray-300">
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-12 col-sm-3 d-flex justify-content-center align-items-center mb-3 mb-sm-0">
            <img
              src={`data:image/jpeg;base64, ${logo}`}
              alt="logo"
              className="w-100 rounded"
              style={{ maxHeight: "80px", objectFit: "contain" }}
            />
          </div>
          <div className="col-12 col-sm-9">
            <h5 className="fw-bold mb-2">{title}</h5>
            <div className="d-flex align-items-center flex-wrap">
              {tags.map((tag, index) => (
                <span key={index} className="badge bg-success me-2 mb-2">
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="text-dark mb-4 fs-12">{displayText}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <div className="border border-gray-300 rounded p-2">
              <div className="text-muted">
                <AccessTimeIcon className="me-2" />
                Deadline
              </div>
              <p className="mb-0 fw-bold">{deadline}</p>
            </div>
          </div>
          <div className="col-12">
            <div className="border border-gray-300 rounded p-2">
              <div className="text-muted">
                <LocationOnIcon className="me-2" />
                Location Scope
              </div>
              <p className="mb-0 fw-bold">{location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityCard;
