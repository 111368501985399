import * as React from "react";

export default function Features() {
  return (
    <section className="container py-5 mt-5" id="features">
      <div className="row align-items-center gx-0">
        {" "}
        {/* Remove horizontal gaps */}
        {/* Text Section */}
        <div className="col-12 col-lg-6 pe-lg-5 mb-4 mb-lg-0">
          <label className="text-uppercase fs-6 my-3">
            FEATURES UNDER IRFP
          </label>
          <h4>What is the Investment Readiness and Fundraising Program?</h4>
          <div className="section-description py-3">
            The Investment Readiness and Fundraising Program (IRFP) is an
            internal ASIGMA initiative designed to assist businesses and
            investors in navigating the challenges they may encounter in their
            deal-making processes.
          </div>
        </div>
        {/* Collage Section */}
        <div className="col-12 col-lg-6">
          <div className="row gx-0">
            {" "}
            {/* Remove horizontal gaps */}
            <div className="col-12 col-md-6 mb-3">
              <div className="collage-card text-white">
                <div className="bg-black p-3 rounded-3">
                  <h6 className="pb-2">Assessment of Business Needs</h6>
                  <p className="card-text">
                    We conduct thorough evaluations to identify key strategic
                    areas for improvement within businesses.
                  </p>
                  <p className="card-text">
                    This is based on the experience we have built working with
                    different investors and businesses...
                  </p>
                  {/* Arrow Icon */}
                  <a href="/" className="danger">
                    <i className="bi bi-arrow-right-circle-fill custom-icon"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <div className="collage-card">
                <div className="bg-red p-3 rounded-3">
                  <h6 className="pb-2">Investor matchmaking</h6>
                  <p className="card-text">
                    We facilitate introductions and engagements between
                    investors and businesses whose goals are aligned.
                  </p>
                  {/* Arrow Icon */}
                  <a href="/" className="danger">
                    <i className="bi bi-arrow-right-circle-fill custom-icon"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <div className="collage-card text-white">
                <div className="bg-pink p-3 rounded-3">
                  <h6 className="pb-2">Transaction Advisory Support</h6>
                  <p className="card-text">
                    We provide expert transaction advisory support tailored to
                    guide both businesses and investors to finalize potential
                    deals...
                  </p>
                  {/* Arrow Icon */}
                  <a href="/#" className="danger">
                    <i className="bi bi-arrow-right-circle-fill custom-icon"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="collage-card">
                <div className="bg-black p-3 rounded-3">
                  <h6 className="pb-2">Pipeline development</h6>
                  <p className="card-text">
                    IRFP assists investors build a pipeline and identify
                    businesses and projects for investment that align with their
                    core values and goals...
                  </p>
                  {/* Arrow Icon */}
                  <a href="/#" className="danger">
                    <i className="bi bi-arrow-right-circle-fill custom-icon"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
