import PageHeader from "../../components/PageHeader";
import React from "react";
import Footer from "../../components/Footer";
import OpportunitiesHeroSection from "../../components/OpportunitiesHeroSection";
import OpportunitiesList from "../../components/OpportunitiesList";
import {
  AllInbox,
  Agriculture,
  School,
  Paid,
  HealthAndSafety,
  Factory,
  Computer,
  FilterList,
} from "@mui/icons-material";

const categories = [
  { name: "All", href: "#", icon: <AllInbox fontSize="small" /> },
  { name: "Agriculture", href: "#", icon: <Agriculture fontSize="small" /> },
  { name: "Education", href: "#", icon: <School fontSize="small" /> },
  { name: "Finance", href: "#", icon: <Paid fontSize="small" /> },
  { name: "Health", href: "#", icon: <HealthAndSafety fontSize="small" /> },
  { name: "Manufacturing", href: "#", icon: <Factory fontSize="small" /> },
  { name: "Technology", href: "#", icon: <Computer fontSize="small" /> },
  { name: "More filters", href: "#", icon: <FilterList fontSize="small" /> },
];

function Opportunities() {
  return (
    <div>
      <PageHeader />
      <OpportunitiesHeroSection categories={categories} />
      <OpportunitiesList />
      <Footer />
    </div>
  );
}
export default Opportunities;
